<template>
  <span>{{ getInvoiceType() }}</span>
</template>

<script>
import { getOptionLabel } from "../../../../helpers/common";
import { getInvoiceTypeOptions } from "../../../../helpers/options";
export default {
  name: "InvoicesTableType",
  methods: {
    getInvoiceType() {
      const options = getInvoiceTypeOptions();
      return getOptionLabel(this.data.i_type, options);
    },
  },
};
</script>
